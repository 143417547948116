import { ReactElement } from "react";

const items: { skill: string, percent: number }[] = [
  { skill: 'HTML/CSS', percent: 93 },
  { skill: 'JavaScript/Node', percent: 90 },
  { skill: 'TypeScript', percent: 90 },
  { skill: 'React', percent: 95 },
  // {skill: 'AngularJs', percent: 95},
  { skill: 'MySQL/PostgreSQL', percent: 60 },
  { skill: 'MongoDb', percent: 90 },
  { skill: 'ExpressJs', percent: 92 },
  { skill: 'SASS', percent: 95 },
  { skill: 'Redux', percent: 80 },
  { skill: 'Material UI', percent: 60 },
  { skill: 'DSA', percent: 60 },
  { skill: 'ES6', percent: 95 },
  { skill: 'Git and Github', percent: 75 },
  { skill: 'Linux', percent: 60 },
  { skill: 'SEO', percent: 70 },
  { skill: 'NextJs', percent: 70 },
  { skill: 'Adobe/Figma', percent: 40 },
  { skill: 'Postman', percent: 70 },
  { skill: 'AWS', percent: 50 },
  { skill: 'TypeOrm/Sequelize', percent: 70 },
  { skill: 'Bootstrap', percent: 70 },
  { skill: 'Bootstrap', percent: 70 },
  { skill: 'RESTful API', percent: 95 },
  { skill: 'GraphQl', percent: 80 },
]

export const skills = items.sort((a, b) => b.percent - a.percent);

export const portfolios: {
  image: string,
  name: string,
  details: string | ReactElement,
  views?: {
    mobile?: string,
    desktop?: string,
  },
  url: string
}[] = [
    {
      name: 'ShiPack website',
      image: '/img/shippack-website.png',
    details:
      <div>
        <p>This is the official website of ShipPack Company Limited</p>
        <br/>
        <p>ShiPpack is a start-up logistics solution for sending and receiving packages. It is presently available for use in Nigeria, Ghana and Kenya.</p>
        <br/>
        <p>I built 60% of the website appplying the following tools: NextJs, SEO, TypeScript, Tailwind CSS, SASS CSS, Firebase</p>
      </div>,
      url: 'https://theshippack.com/'
    },
    {
      name: 'ShiPack Console website',
      image: '/img/shippack-console.png',
      details:
      <div>
          <p>This is the official website of ShipPack Company Limited</p>
          <br/>
          <p>ShiPpack is a start-up logistics solution for sending and receiving packages. It is presently available for use in Nigeria, Ghana and Kenya.</p>
          <br/>
          <p> I built 90% of the website appplying the following tools: NextJs, TypeScript, Redux, React Recharts, Google Maps API, Tailwind CSS, SASS CSS, Firebase</p>
        </div>,
      url: 'https://panel.theshippack.com/'
    },

    {
      name: 'Jesus On The Throne International Ministry',
      image: '/img/jottim.png',
      details: `Full stack Next js web application built with TypeScript, SASS CSS Tailwind for styling, MongoDb database, deployed on render and custom domain on Namecheap.
      `,
      url: 'https://www.jottim.com'
    },
    {
      name: 'My Portfolio',
      image: '/img/portfolio.png',
      details: `Frontend with React TypeScript, SASS and deployed on AWS Amplify with 
      serverless backend to take count of page visitors and also handle messaging 
      with the Nodemailer API.
      `,
      url: 'https://www.okafordavid.com'
    },
    // { 
    //   name: 'ShipPack',
    //   image:'img/shippack-website.png',
    //   details: <p>NextJs, TypeScript, Redux, Tailwind CSS, SASS CSS, Firebase</p>,
    //   views: {
    //     mobile: "https://shippack-website-staging.vercel.app",
    //     desktop: "https://shippack-panel-staging.vercel.app"
    //   },
    //   // url: 'https://theshippack.com/'//make this line an empty string to have both staging link active
    //   url:''

    //   // https://shippack-website-staging.vercel.app
    // },
    {
      name: 'Agro Capital Investment Limited',
      image: '/img/agrocil.jpg',
      details: 'Agrocil is an investment programme where investors can invest and earn. Investors can also earn via inviting new users. Agrocil is a product of the MERN stack with cookie based authentication. Payment methods implemented are fincra and paystack.',
      url: 'https://agrocil.onrender.com'
    },
    {
      name: 'Shoppinz',
      image: '/img/shoppinz.png',
      details: 'One of my fun and hobby project where I implemented three payment plaftorms (PayPal, PayStack and Fincra payment). Built with React TypeScript, NodeJs, MongoDb, Tailwind CSS, SASS CSS and deployed on render',
      url: 'https://shoppinz.onrender.com/'
    },
    {
      name: 'Dayve Software',
      image: '/img/software.png',
      details: 'Dayve Software allows a potential client to calculate the estimate of the application he/she seeks. Client is presented with various possible functionalities and can pick desired ones. The options picked determines the final cost of building the intended application. Finally, client can make order for application to  be built with custom and selected features. Built with ReactJs and Material UI',
      url: 'https://dayvesoftware.onrender.com'
    },
    {
      name: 'Eazypay Frontend',
      image: '/img/eazipay.jpg',
      details: 'www.eazipay.com frontend built with ReactJs and css sass and deployed on AWS amplify',
      url: 'https://main.d29aep8b07gcko.amplifyapp.com/'
    },
    {
      name: 'Movie Search',
      image: '/img/movie.jpg',
      details: 'Built with React and css sass. Explores the OMDB API to search for all kind of movies',
      url: 'https://main.d3p0i0hl9mn2ra.amplifyapp.com/'
    },
    {
      name: 'Piggy Bank',
      image: '/img/piggybank.jpg',
      details: 'Built with ReactJs, integrating the Ethereum API and deployed on Netlify',
      url: 'https://elegant-nobel-9f06be.netlify.app'
    },
    // { 
    //   name: 'Natours',
    //   image:'img/natours.jpg',
    //   details: 'Built with React, SaSS CSS and deployed on Netlify',
    //   url: 'https://singular-semifreddo-5d9880.netlify.app'
    // },
    // { 
    //   name: 'Trillo',
    //   image:'img/trillo.jpg',
    //   details: 'Built with React, SaSS CSS and deployed on Netlify',
    //   url: 'https://sweet-paprenjak-393993.netlify.app/'
    // },
    // { 
    //   name: 'Nexter',
    //   image:'img/nexter.jpg',
    //   details: 'Built with React, SaSS CSS and deployed on Netlify',
    //   url: 'https://meek-fenglisu-e45476.netlify.app'
    // },

  ]